<template>
  <CBox>
    <Loading :active="isLoading" />
    <CBox
      :mt="['12px', '12px', '20px', '20px']"
      min-w="100px"
      w="100%"
      max-w="100%"
      min-h="100vh"
    >
      <CGrid templateColumns="12" :gap="[3, 3, 6, 6]">
        <CBox>
          <AppDescription
            :currentAppType="currentAppType"
            :useMoreDesc="true"
          />
          <CBox
            v-if="checkEmpObject(currentTest.result)"
            bg="white"
            :mt="['12px', '12px', '20px', '20px']"
            minH="100px"
            borderRadius="5px"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          >
            <CBox pt="20px" pb="10px" mx="20px">
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="16px"
                fontWeight="bold"
                color="fontMain"
                >JOB ID {{ currentTest.id }}</CText
              >
            </CBox>
            <CDivider />
            <CBox pb="20px" mx="20px">
              <CGrid
                templateColumns="1fr 3fr"
                :gap="6"
                w="100%"
                mb="10px"
                mt="20px"
              >
                <CText
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  fontSize="14px"
                  color="fontInformation"
                  >NAME</CText
                >
                <CText
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  fontSize="14px"
                  fontWeight="600"
                  color="fontMain"
                  >{{ currentTest.user.emp_full_name }}</CText
                >
              </CGrid>
              <CGrid
                v-if="currentAppType.type === 'BE'"
                templateColumns="1fr 3fr"
                :gap="6"
                w="100%"
                mb="20px"
              >
                <CText
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  fontSize="14px"
                  color="fontInformation"
                ></CText>
                <CBox d="flex" class="pointer">
                  <CText
                    @click.native="
                      $router.push({
                        name: 'Detail Job',
                        params: {
                          id: v.id,
                        },
                      })
                    "
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="14px"
                    fontWeight="600"
                    color="activeMenu"
                    class="pointer"
                    >Detail</CText
                  >
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="14px"
                    fontWeight="600"
                    color="activeMenu"
                    >DETAIL LOG</CText
                  >
                  <CImage
                    pl="5px"
                    :src="require('@/assets/icon/ic-link.svg')"
                  />
                </CBox>
              </CGrid>
              <CBox align="center" my="100px">
                <CImage :src="require('@/assets/img/aircraft.png')" mx="auto" />
                <CBox mt="50px" align="center">
                  <CBox d="flex" direction="row" justifyContent="center">
                    <CImage
                      :src="require('@/assets/icon/ic-loading.gif')"
                      w="40px"
                    />
                    <CText
                      pt="10px"
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="16px"
                      fontWeight="500"
                      color="fontMain"
                    >
                      System sedang melakukan proses testing
                    </CText>
                  </CBox>
                </CBox>
              </CBox>
            </CBox>
          </CBox>
          <CBox
            v-if="!checkEmpObject(currentTest.result)"
            bg="white"
            mt="20px"
            minH="100px"
            borderRadius="5px"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          >
            <CBox pt="20px" pb="10px" mx="20px">
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="16px"
                fontWeight="bold"
                color="fontMain"
                >JOB ID {{ currentTest.id }}</CText
              >
            </CBox>
            <CDivider />
            <CBox pt="20px" py="30px" mx="20px">
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="20px"
                fontWeight="bold"
                color="fontMain"
                >Log Detail</CText
              >
            </CBox>
            <CBox h="20px" bgColor="bgTestList" />
            <CBox
              v-if="currentAppType.type !== 'PLAYGROUND'"
              px="20px"
              bgColor="bgTestList"
            >
              <CBox
                borderWidth="1px"
                borderRadius="md"
                borderColor="borderTest"
                bg="white"
                w="100%"
              >
                <CBox
                  d="flex"
                  px="20px"
                  py="10px"
                  justifyContent="space-between"
                  borderBottomWidth="1px"
                  borderBottomColor="borderTest"
                >
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="12px"
                    fontWeight="700"
                    color="fontMain"
                  >
                    <pre v-if="currentAppType.type === 'BE'">
                    {{ currentTest.logoutput }}
                  </pre
                    >
                    <div
                      v-else-if="currentAppType.type == 'LOGIC' && recorderUrl"
                    >
                      <CLink
                        color="linkColor"
                        fontFamily="Montserrat"
                        fontSize="14px"
                        :href="recorderUrl"
                        target="_blank"
                        >Video recording</CLink
                      >
                    </div>
                    <div v-else>
                      <video width="100%" height="600" autoplay muted controls>
                        <source :src="currentTest.logoutput" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </CText>
                </CBox>
              </CBox>
            </CBox>
            <CBox
              v-else-if="currentAppType.type === 'PLAYGROUND'"
              px="20px"
              bgColor="bgTestList"
            >
              <CBox
                v-for="(v, i) in currentTest.result.detail[0].tests"
                :key="i"
                borderWidth="1px"
                borderRadius="md"
                borderColor="borderTest"
                bg="white"
                w="100%"
              >
                <CBox
                  px="20px"
                  py="10px"
                  bgColor="white"
                  justifyContent="space-between"
                  borderBottomWidth="2px"
                  borderBottomColor="borderTest"
                >
                  <CBox>
                    <CText
                      d="flex"
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="16px"
                      fontWeight="700"
                      color="fontMain"
                      justifyContent="space-between"
                    >
                      Soal: {{ v.title }}
                    </CText>
                    <CBox display="flex" flexDirection="column" gap="12px">
                      <CBox
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <CText fontWeight="700" fontSize="12px">
                          Total Point
                        </CText>
                        <CText
                          bgColor="#D3D3D3"
                          padding="5px"
                          width="20px"
                          height="20px"
                          fontSize="10px"
                          borderRadius="50%"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          color="white"
                          fontWeight="700"
                        >
                          {{ v.point }}
                        </CText>
                      </CBox>
                      <CBox
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        marginTop="5px"
                      >
                        <CText
                          fontFamily="Montserrat"
                          fontStyle="normal"
                          fontSize="12px"
                          fontWeight="700"
                        >
                          Status:
                        </CText>
                        <CText
                          fontWeight="700"
                          d="inline"
                          fontSize="12px"
                          :color="v.state == 'passed' ? '#00A790' : 'danger'"
                          >{{ v.state.toUpperCase() }}
                        </CText>
                      </CBox>
                      <CBox
                        display="flex"
                        alignItems="center"
                        cursor="pointer"
                        class="ai-output"
                        @click="toggleCollapse(i)"
                        v-if="
                          v.answerResult &&
                          (v.answerResult.length || v?.answerResult?.PronScore)
                        "
                      >
                        <CBox
                          class="collapse-toggle"
                          fontSize="16px"
                          textAlign="center"
                          padding="2px"
                          borderRadius="50%"
                          :style="{
                            transform: !isNotCollapsed[i]
                              ? 'rotate(90deg)'
                              : '',
                            transition: 'transform 0.3s ease-in-out',
                          }"
                        >
                          ▶
                        </CBox>
                        <CText fontWeight="700" fontSize="10px">
                          Output from AI Review
                        </CText>
                      </CBox>
                    </CBox>
                  </CBox>
                  <CBox v-show="!isNotCollapsed[i]" class="collapse-content">
                    <CBox
                      v-if="
                        v.answerResult &&
                        !(v.answerResult.length || v?.answerResult?.PronScore)
                      "
                      borderWidth="2px"
                      :key="id"
                      display="flex"
                      marginBottom="10px"
                      paddingX="12px"
                      paddingY="4px"
                    >
                      <CText fontWeight="600" fontSize="10px">
                        Soal belum terjawab
                      </CText>
                    </CBox>
                    <CBox v-else-if="v?.answerResult?.PronScore">
                      <CText fontWeight="600" fontSize="10px">
                        Accuracy Score: {{ v?.answerResult?.AccuracyScore }}
                      </CText>
                      <CText fontWeight="600" fontSize="10px">
                        Completeness Score:
                        {{ v?.answerResult?.CompletenessScore }}
                      </CText>
                      <CText fontWeight="600" fontSize="10px">
                        Fluency Score: {{ v?.answerResult?.FluencyScore }}
                      </CText>
                      <CText fontWeight="600" fontSize="10px">
                        Prosody Score: {{ v?.answerResult?.ProsodyScore }}
                      </CText>
                    </CBox>
                    <CText
                      v-else
                      v-for="(value, idx) in v.answerResult"
                      :key="idx"
                      fontSize="12px"
                    >
                      {{ value.aspect }}
                      <CBox
                        borderWidth="2px"
                        v-for="(item, id) in value.aspectResult"
                        :key="id"
                        display="flex"
                        flexDirection="column"
                        marginBottom="10px"
                        paddingX="12px"
                        paddingY="4px"
                      >
                        <CText
                          v-if="item.userContent !== ''"
                          fontWeight="600"
                          fontSize="10px"
                          >{{ item.userContent }}</CText
                        >
                        <CText v-else fontWeight="600" fontSize="10px"
                          >No user content</CText
                        >

                        <CText
                          v-if="item.expectedContent !== ''"
                          fontWeight="600"
                          color="gray"
                          fontSize="10px"
                          >Expected Answers: {{ item.expectedContent }}</CText
                        >
                        <CText
                          v-else
                          fontWeight="600"
                          color="gray"
                          fontSize="10px"
                          >No expected content</CText
                        >
                        <CBox
                          display="flex"
                          justifyContent="space-between"
                          marginTop="10px"
                        >
                          <CBox display="flex" alignItems="center">
                            <CText
                              padding="5px"
                              fontSize="10px"
                              :class="getStyle(item.category)"
                              fontWeight="700"
                              >{{ item.category }}</CText
                            >
                            <CBox display="flex flex-col" marginLeft="6px">
                              <CText fontWeight="400" fontSize="10px">{{
                                item.reason
                              }}</CText>
                              <CText
                                v-if="item.suggestion !== 'N/A'"
                                fontWeight="600"
                                color="black"
                                fontSize="10px"
                                >Suggestion: {{ item.suggestion }}</CText
                              >
                            </CBox>
                          </CBox>
                          <CBox display="flex" alignItems="center">
                            <CText fontWeight="700" fontSize="12px">
                              Points:
                            </CText>
                            <CText
                              marginLeft="6px"
                              bgColor="#D3D3D3"
                              padding="5px"
                              width="20px"
                              height="20px"
                              fontSize="10px"
                              borderRadius="50%"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              color="white"
                              fontWeight="700"
                            >
                              {{ item.point }}
                            </CText>
                          </CBox>
                        </CBox>
                      </CBox>
                    </CText>
                  </CBox>
                </CBox>
              </CBox>
            </CBox>
            <CBox h="20px" bgColor="bgTestList" />
          </CBox>
        </CBox>
      </CGrid>
    </CBox>
  </CBox>
</template>

<script>
import { GET_TEST } from "@/store/test.module";
import { GET_APP_TYPE } from "@/store/app-types.module";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import _ from "lodash";
import config from "@/ConfigProvider";
import AppDescription from "@/components/AppDescription.vue";
import { GET_USER } from "@/store/auth.module";
import ApiService from "@/store/api.service";

export default {
  metaInfo() {
    return {
      title: this.$route.params.id,
      titleTemplate: "Devcode - %s",
    };
  },
  components: {
    Loading,
    AppDescription,
  },
  data() {
    return {
      isLoading: false,
      baseUrl: config.value("DEVCODE_WEB_URL"),
      blogUrl: config.value("DEVCODE_BLOG_URL"),
      notPassedInformation:
        "Maaf aplikasi yang kamu submit tidak memenuhi <b>70%</b> dari test scenario.<br>Perhatikan kembali test scenario dan requirement aplikasi yang ada, untuk mendapatkan <b>Sertifikat</b> dari kami.",
      recorderUrl: null,
      // showDownload: false,
      labelStyle: {
        match: "match",
        not_match: "not_match",
        irrelevant: "irrelevant",
        unsure: "unsure",
      },
      isNotCollapsed: [],
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    currentTest() {
      return this.$store.getters.currentTest;
    },
    currentAppType() {
      return this.$store.getters.currentAppType;
    },
    currentUser() {
      const user = this.$store.getters.currentUser;
      return user;
    },
    showDownload() {
      let result = false;
      if (
        this.currentUser &&
        this.currentUser.emp_id == this.currentTest.emp_id
      ) {
        result = true;
      } else {
        result = false;
      }
      return result;
    },
  },
  methods: {
    openBlog() {
      window.open(this.blogUrl, "_blank");
    },
    openSite(url) {
      window.open(url, "_blank");
    },
    toggleCollapse(index) {
      this.$set(this.isNotCollapsed, index, !this.isNotCollapsed[index]);
    },
    // autoload() {
    //   if (this.checkEmpObject(this.currentTest.result)) {
    //     this.getautoload = setInterval(() => {
    //       this.$store.dispatch(GET_TEST, this.$route.params.id);
    //     }, 10000);
    //   }
    // },
    checkEmpObject(obj) {
      return _.isEmpty(obj);
    },
    async getData() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(GET_TEST, this.$route.params.id);
        await Promise.allSettled([
          this.$store.dispatch(GET_APP_TYPE, this.currentTest.app_type_id),
          this.$store.dispatch(GET_USER),
        ]);
        // this.autoload();
        this.isLoading = false;

        const recordingIsPublic =
          this.currentAppType.settings?.is_recording_public ?? false;
        if (this.currentAppType.type === "LOGIC" && !recordingIsPublic) {
          window.location.href = this.$route.params.track
            ? "/track/" +
              this.$route.params.track +
              "/job/" +
              this.currentTest.id
            : "/job/" + this.currentTest.id;
        } else {
          const filename = this.currentTest.id + ".webm";
          ApiService.post("/recording", {
            filename,
          })
            .then(({ data }) => {
              this.recorderUrl = data?.data;
            })
            .catch((err) => console.log("err", err));
        }
      } catch (err) {
        this.isLoading = false;
        if (err.code !== 401) {
          Swal.fire({
            title: "Oops . .",
            text: err.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        }
      }
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    onProgress() {
      // this.isLoading = true;
    },
    hasGenerated() {
      // this.isLoading = false;
    },
    setClipboard(value) {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      this.$toast({
        title: "Copied",
        status: "success",
        duration: 3000,
        position: "top",
        isClosable: false,
      });
    },
    getStyle(label) {
      const styleKey = label.toLowerCase().replace(" ", "_");
      console.log(label, styleKey, this.labelStyle[styleKey]);
      return this.labelStyle[styleKey];
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getData();
      },
    },
  },
  // destroyed() {
  //   clearInterval(this.getautoload);
  // },
};
</script>
<style scoped>
.match {
  color: #0eab33;
  background-color: #c6f6d5;
}

.not_match {
  color: #ff0000;
  background-color: #ffe4e6;
}

.irrelevant {
  color: #ffd602;
  background-color: #fff9c4;
}

.unsure {
  color: #ffa602;
  background-color: #fef3c7;
}

.categories-container {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.category {
  border-radius: 0.375rem;
  padding: 0.5rem;
}

.collapse-toggle {
  cursor: pointer;
}

.ai-output:hover {
  background-color: rgb(240, 238, 238);
  border-radius: 5px;
}
</style>
